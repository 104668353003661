import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Introduction`}</h2>
    <p>{`Our take on login and register pattern is to provide a clear context on every action our users need to do, so they won’t spend too much time onboarding themselves into our ecosystem. This pattern is categorized based on its visual component.`}</p>
    <p>{`Along with the development of technology, users can log in in various ways. Starting from the most common, enter your username and password, up to using mail, cell phone numbers, and social media that have been integrated into the system.`}</p>
    <div className="divi" />
    <h2>{`Data Protection (UU PDP) Compliance`}</h2>
    <p>{`In accordance with the Personal Data Protection Act (UU PDP) in Indonesia, our login and registration processes are designed to ensure the highest standards of data security and user privacy. We are committed to complying with all regulations set forth by the UU PDP, ensuring that personal data is handled with the utmost care and confidentiality.`}</p>
    <p>{`Our system employs robust security measures to protect user data during registration and login. This includes encrypted storage of passwords, secure communication channels, and regular audits to maintain compliance with data protection laws. By adhering to these standards, we provide our users with a safe and secure online experience.`}</p>
    <div className="divi" />
    <h2>{`Forms`}</h2>
    <h3><strong parentName="h3">{`Title`}</strong></h3>
    <p>{`Here are the rules in crafting copy for title in login and registration journey:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Use Title Case`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`It should inform about what happen on this page while also give context to users. For example:`}</p>
      </li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/title-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/title-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Use a short, straightforward one-line sentence, due to limited space for title. For example:`}</li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/title-do-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/title-dont-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Refrain from using gimmicky sentences since they might hinder the user’s promptness to follow the flow, especially if you craft copywriting for B2B or B2G products. For example:`}</li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/title-do-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/title-dont-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`If it requires experience in multiple languages, try writing the title in the same length`}</li>
    </ol>
    <div className="divi" />
    <h3><strong parentName="h3">{`Body`}</strong></h3>
    <p>{`The purpose of body copy in product screen, mainly is to provide additional context, information or instruction about what user can perform. It also create user experience as  a whole along with the UX and UI design. Besides using information architecture to highlight which body copy is more important than the others, UX writer can also use typography.`}</p>
    <p>{`Here are the rules in crafting copy for body in login and registration journey:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`To prevent unnecessary word that can tamper with space and user cognitive processing, try to plan what information will you deliver in body copy`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use the Sentence case`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Do it in a short, concise way. Max. 2-3 lines`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Refrain from including promotional messages in this section. You may write that on the onboarding pattern instead`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h3><strong parentName="h3">{`Form`}</strong></h3>
    <p>{`Form is used to gather data, which in login and registration journey is very important`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`For the title, use Title Case. Other than that (e.g., error message, placeholder text, etc.), use the Sentence case`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The title should clearly state what information the user needs to input and whether it’s required or optional`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Give placeholder text a proper yet concise direction on how to fill that column with an example`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Depends on the tone of voice, you can appease user using “Silakan, Maaf etc.” instead of direct instruction such as “Masukkan”`}</p>
      </li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="16:9" colLg="12" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/form-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h3><strong parentName="h3">{`Call to Action (CTA)`}</strong></h3>
    <p><strong parentName="p">{`Requirement or Acceptance Criteria:`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`All CTAs/button copies should use Title Case.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Limit the copy to a maximum length of 3 words.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Always use an active, plain word`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Always use an active, plain word`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The wording for cancelingcancelling action may use 2 words to prevent ambiguity of an action`}</p>
      </li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/cta-do.png",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/cta-dont.png",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Dialog`}</h2>
    <h2><strong parentName="h2">{`Selection Dialog`}</strong></h2>
    <p>{`Clearly explain the options available for the user and explicitly inform them that they only need to choose one.`}</p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="16:9" colLg="12" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/selection-dialog-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h3><strong parentName="h3">{`Success Dialog`}</strong></h3>
    <p>{`Give more context to the description and ease the user by informing them what’s next`}</p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="16:9" colLg="12" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/success-dialog-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h3><strong parentName="h3">{`OTP Entry Dialog`}</strong></h3>
    <p><strong parentName="p">{`Requirement or Acceptance Criteria:`}</strong></p>
    <ol>
      <li parentName="ol">{`We can use a countdown timer as part of the copy if needed.`}</li>
    </ol>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Ensure it’s showing the time left before the user can redo or reselect their action.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remember to use HH:MM:SS time format.`}</p>
      </li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Help text should clearly state what kind of support the user could get`}</li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/otp-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "/images/legion/pattern/login-and-register/otp-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h3><strong parentName="h3">{`Error State`}</strong></h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Use a short, straightforward one-line sentence that does not exceed the length of the corresponding form`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`An error message should contain the reason that caused that fault and the solution`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If there’s not enough space to include both reason and solution, prioritize presenting the solution`}</p>
      </li>
    </ol>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      